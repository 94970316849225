import React from "react";

import { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { ChangeEvent } from "react";
import { FormEvent } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import Button from "@Js/Component/Button";
import Gutenberg from "@Js/Component/Gutenberg";
import Heading from "@Js/Component/Heading";
import Input from "@Js/Component/Input";
import Textarea from "@Js/Component/Textarea";

import isSSR from "@Js/Utils/isSSR";

import * as classNames from "@Css/Component/Page/Contact.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        contactSettings: {
            companies: {
                companyOneCity: string;
                companyOneCoc: string;
                companyOneCountry: string;
                companyOneEmailAddress: string;
                companyOneName: string;
                companyOnePhoneNumber: string;
                companyOnePostalCode: string;
                companyOneStreet: string;
                companyOneTermsAndConditions: {
                    link: string;
                };
                companyOneVat: string;
                companyTwoCity: string;
                companyTwoCoc: string;
                companyTwoCountry: string;
                companyTwoEmailAddress: string;
                companyTwoName: string;
                companyTwoPhoneNumber: string;
                companyTwoPostalCode: string;
                companyTwoStreet: string;
                companyTwoTermsAndConditions: {
                    link: string;
                };
                companyTwoVat: string;
            };
            contact: {
                emailAddress: string;
                phoneNumber: string;
            };
            contactSupport: {
                emailAddressSupport: string;
                phoneNumberSupport: string;
            };
        };
    };
};

/**
 * @type WordpressGravityFormsFormData
 */
type WordpressGravityFormsFormData = {
    wordpressGravityFormsForm: {
        button: {
            text: string;
        };
        confirmations: {
            isDefault: boolean;
            message: string;
        }[];
        description: string;
        formFields: {
            nodes: ({
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "email";
            } | {
                id: number;
                inputs: {
                    id: number;
                    isHidden: boolean;
                    label?: string;
                    placeholder?: string;
                }[];
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                type: "name";
            } | {
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "phone";
            } | {
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "text";
            } | {
                id: number;
                isRequired: boolean;
                label?: string;
                layoutGridColumnSpan?: number;
                placeholder?: string;
                type: "textarea";
            })[];
        };
        title: string;
    };
};

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content?: string;
        extraContent?: string;
        seo: {
            canonical: string;
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphAuthor: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            schema: {
                raw: string;
            };
            title: string;
        };
        topImages: {
            topImageOne: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
            topImageTwo: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
        };
    };
};

/**
 * @type SubmitGravityFormsFormMutationResult
 */
type SubmitGravityFormsFormMutationResult = {
    data: {
        submitGravityFormsForm: {
            errors?: {
                id: number;
                message: string;
            }[];
        };
    };
};

/**
 * @type ContactProps
 */
export type ContactProps = PageProps;

/**
 * @const Contact
 */
const Contact = (props: ContactProps, ref: Ref<HTMLElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const [values, setValues] = useState<{ [id: number]: string; }>({});
    const [errors, setErrors] = useState<{ [id: number]: string; }>({});

    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [isSubmittingGravityFormsForm, setIsSubmittingGravityFormsForm] = useState(false);

    const data: WordpressData & WordpressGravityFormsFormData & WordpressPageData = useStaticQuery(graphql`
        query {
            wordpress {
                contactSettings {
                    companies {
                        companyOneCity
                        companyOneCoc
                        companyOneCountry
                        companyOneEmailAddress
                        companyOneName
                        companyOnePhoneNumber
                        companyOnePostalCode
                        companyOneStreet
                        companyOneTermsAndConditions {
                            link
                        }
                        companyOneVat
                        companyTwoCity
                        companyTwoCoc
                        companyTwoCountry
                        companyTwoEmailAddress
                        companyTwoName
                        companyTwoPhoneNumber
                        companyTwoPostalCode
                        companyTwoStreet
                        companyTwoTermsAndConditions {
                            link
                        }
                        companyTwoVat
                    }
                    contact {
                        emailAddress
                        phoneNumber
                    }
                    contactSupport {
                        emailAddressSupport
                        phoneNumberSupport
                    }
                }
            }
            wordpressGravityFormsForm(formId: { eq: 1 }) {
                button {
                    text
                }
                confirmations {
                    isDefault
                    message
                }
                description
                formFields {
                    nodes {
                        ... on WordpressEmailField {
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                        ... on WordpressNameField {
                            id
                            inputs {
                                id
                                isHidden
                                label
                                placeholder
                            }
                            isRequired
                            label
                            layoutGridColumnSpan
                            type
                        }
                        ... on WordpressPhoneField {
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                        ... on WordpressTextField {
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                        ... on WordpressTextAreaField {
                            id
                            isRequired
                            label
                            layoutGridColumnSpan
                            placeholder
                            type
                        }
                    }
                }
                title
            }
            wordpressPage(isContactPage: { eq: true }) {
                content
                extraContent
                seo {
                    canonical
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    schema {
                        raw
                    }
                    title
                }
                topImages {
                    topImageOne {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                    topImageTwo {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                }
            }
        }
    `);

    const confirmation = data.wordpressGravityFormsForm.confirmations.find((confirmation) => {
        return confirmation.isDefault;
    });

    const onFieldChange = (id: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const copyOfValues = { ...values };

        copyOfValues[id] = event.currentTarget.value;

        setValues(copyOfValues);
    };

    const onFormSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setErrors({});

        setIsSubmittingGravityFormsForm(true);

        const url = process.env.WORDPRESS_GRAPHQL_URL as string;

        let fieldValues = "";

        data.wordpressGravityFormsForm.formFields.nodes.forEach((node) => {
            switch (node.type) {
                case "email": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            emailValues: {
                                value: "${ values[node.id] }"
                            }
                        }
                    `;

                    break;
                }

                case "name": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            nameValues: {
                                first: "${ values[node.id + 0.3] }"
                                last: "${ values[node.id + 0.6] }"
                            }
                        }
                    `;

                    break;
                }

                case "phone":
                case "text": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            value: "${ values[node.id] }"
                        }
                    `;

                    break;
                }

                case "textarea": {
                    fieldValues += `
                        {
                            id: ${ node.id }
                            value: "${ values[node.id].replaceAll("\n", "\\n") }"
                        }
                    `;

                    break;
                }
            }
        });

        const body = JSON.stringify({
            query: `
                mutation {
                    submitGravityFormsForm(
                        input: {
                            fieldValues: [
                                ${ fieldValues }
                            ]
                            formId: 1
                        }
                    ) {
                        errors {
                            id
                            message
                        }
                    }
                }
            `
        });

        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        const method = "POST";

        const response = await fetch(url, {
            "body": body,
            "headers": headers,
            "method": method
        });

        const result = await response.json() as SubmitGravityFormsFormMutationResult;

        if (result.data.submitGravityFormsForm.errors) {
            const errors: { [id: number]: string; } = {};

            result.data.submitGravityFormsForm.errors.forEach((error) => {
                errors[error.id] = error.message;
            });

            setErrors(errors);
        }
        else {
            setIsConfirmationVisible(true);

            if (!(isSSR())) {
                window.dataLayer.push({
                    event: "contact"
                });
            }
        }

        setIsSubmittingGravityFormsForm(false);
    };

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    useEffect(() => {
        const values: { [id: number]: string; } = {};

        data.wordpressGravityFormsForm.formFields.nodes.forEach((node) => {
            switch (node.type) {
                case "email": {
                    values[node.id] = "";

                    break;
                }

                case "name": {
                    node.inputs.forEach((input) => {
                        values[input.id] = "";
                    });

                    break;
                }

                case "phone":
                case "text":
                case "textarea": {
                    values[node.id] = "";

                    break;
                }
            }
        });

        setValues(values);
    }, []);

    return (
        <>
            <Seo { ...data.wordpressPage.seo } />
            <section className={ classNames.contact } ref={ ref }>
                { data.wordpressPage.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.content } />
                ) }
                <div className={ classNames.container }>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.block }>
                            <strong className={ classNames.title }>{ t("General") }</strong>
                            <span>
                                <a
                                    className={ classNames.phoneNumber }
                                    href={ `tel:${ data.wordpress.contactSettings.contact.phoneNumber }` }
                                >
                                    <span className={ classNames.label }>
                                        { data.wordpress.contactSettings.contact.phoneNumber }
                                    </span>
                                </a>
                            </span>
                            <span>
                                <a
                                    className={ classNames.emailAddress }
                                    href={ `mailto:${ data.wordpress.contactSettings.contact.emailAddress }` }
                                >
                                    <span className={ classNames.label }>
                                        { data.wordpress.contactSettings.contact.emailAddress }
                                    </span>
                                </a>
                            </span>
                        </div>
                        <div className={ classNames.block }>
                            <strong className={ classNames.title }>{ t("Support") }</strong>
                            <span>
                                <a
                                    className={ classNames.phoneNumber }
                                    href={ `tel:${ data.wordpress.contactSettings.contactSupport.phoneNumberSupport }` }
                                >
                                    <span className={ classNames.label }>
                                        { data.wordpress.contactSettings.contactSupport.phoneNumberSupport }
                                    </span>
                                </a>
                            </span>
                            <span>
                                <a
                                    className={ classNames.emailAddress }
                                    href={ `mailto:${ data.wordpress.contactSettings.contactSupport.emailAddressSupport }` }
                                >
                                    <span className={ classNames.label }>
                                        { data.wordpress.contactSettings.contactSupport.emailAddressSupport }
                                    </span>
                                </a>
                            </span>
                        </div>
                    </div>
                    <section className={ classNames.formWrapper } id="contact-form">
                        <Heading className={ classNames.heading }>
                            { data.wordpressGravityFormsForm.title }
                        </Heading>
                        <p className={ classNames.description }>{ data.wordpressGravityFormsForm.description }</p>
                        { isConfirmationVisible && (
                            <div className={ classNames.confirmation }>
                                { confirmation && confirmation.message }
                            </div>
                        ) }
                        { !(isConfirmationVisible) && (
                            <form className={ classNames.form } onSubmit={ onFormSubmit }>
                                <div className={ classNames.fields }>
                                    { data.wordpressGravityFormsForm.formFields.nodes.map((node) => {
                                        const spanClassNames = {
                                            [classNames.spanOne]: node.layoutGridColumnSpan == 1,
                                            [classNames.spanTwo]: node.layoutGridColumnSpan == 2,
                                            [classNames.spanThree]: node.layoutGridColumnSpan == 3,
                                            [classNames.spanFour]: node.layoutGridColumnSpan == 4,
                                            [classNames.spanFive]: node.layoutGridColumnSpan == 5,
                                            [classNames.spanSix]: node.layoutGridColumnSpan == 6,
                                            [classNames.spanSeven]: node.layoutGridColumnSpan == 7,
                                            [classNames.spanEight]: node.layoutGridColumnSpan == 8,
                                            [classNames.spanNine]: node.layoutGridColumnSpan == 9,
                                            [classNames.spanTen]: node.layoutGridColumnSpan == 10,
                                            [classNames.spanEleven]: node.layoutGridColumnSpan == 11,
                                            [classNames.spanTwelve]: node.layoutGridColumnSpan == 12
                                        };

                                        return (
                                            <Fragment key={ node.id }>
                                                { node.type == "email" && (
                                                    <div className={ clsx(classNames.field, spanClassNames) }>
                                                        <label className={ classNames.labelWrapper }>
                                                            <span className={ classNames.label }>
                                                                { node.label }
                                                            </span>
                                                            <Input
                                                                className={ classNames.input }
                                                                onChange={ onFieldChange(node.id) }
                                                                placeholder={
                                                                    `${ node.placeholder } ${ node.isRequired ?
                                                                        "*" : ""
                                                                    }`
                                                                }
                                                                required={ node.isRequired }
                                                                type="email"
                                                                value={ values[node.id] || "" }
                                                            />
                                                        </label>
                                                    </div>
                                                ) }
                                                { node.type == "name" && (
                                                    <fieldset
                                                        className={ clsx(classNames.fieldset, spanClassNames) }
                                                    >
                                                        { node.inputs && node.inputs.filter(
                                                            (input) => !(input.isHidden)).map(
                                                            (input, index) => (
                                                                <label
                                                                    className={ classNames.labelWrapper }
                                                                    key={ input.id }
                                                                >
                                                                    <span className={ clsx(classNames.label, {
                                                                        [classNames.hidden]: index == 1 }
                                                                    ) }>
                                                                        { node.label }
                                                                    </span>
                                                                    <Input
                                                                        className={ classNames.input }
                                                                        onChange={ onFieldChange(input.id) }
                                                                        placeholder={
                                                                            `${ input.placeholder } ${ node.isRequired ?
                                                                                "*" : ""
                                                                            }`
                                                                        }
                                                                        required={ node.isRequired }
                                                                        type="text"
                                                                        value={ values[input.id] || "" }
                                                                    />
                                                                </label>
                                                            )
                                                        )
                                                        }
                                                    </fieldset>
                                                ) }
                                                { node.type == "phone" && (
                                                    <div className={ clsx(classNames.field, spanClassNames) }>
                                                        <label className={ classNames.labelWrapper }>
                                                            <span className={ classNames.label }>
                                                                { node.label }
                                                            </span>
                                                            <Input
                                                                className={ classNames.input }
                                                                onChange={ onFieldChange(node.id) }
                                                                placeholder={
                                                                    `${ node.placeholder } ${ node.isRequired ?
                                                                        "*" : ""
                                                                    }`
                                                                }
                                                                required={ node.isRequired }
                                                                type="tel"
                                                                value={ values[node.id] || "" }
                                                            />
                                                        </label>
                                                    </div>
                                                ) }
                                                { node.type == "text" && (
                                                    <div className={ clsx(classNames.field, spanClassNames) }>
                                                        <label className={ classNames.labelWrapper }>
                                                            <span className={ classNames.label }>
                                                                { node.label }
                                                            </span>
                                                            <Input
                                                                className={ classNames.input }
                                                                onChange={ onFieldChange(node.id) }
                                                                placeholder={
                                                                    `${ node.placeholder } ${ node.isRequired ?
                                                                        "*" : ""
                                                                    }`
                                                                }
                                                                required={ node.isRequired }
                                                                type="text"
                                                                value={ values[node.id] || "" }
                                                            />
                                                        </label>
                                                    </div>
                                                ) }
                                                { node.type == "textarea" && (
                                                    <div className={ clsx(classNames.field, spanClassNames) }>
                                                        <label className={ classNames.labelWrapper }>
                                                            <span className={ classNames.label }>
                                                                { node.label }
                                                            </span>
                                                            <Textarea
                                                                className={ classNames.textarea }
                                                                onChange={ onFieldChange(node.id) }
                                                                placeholder={
                                                                    `${ node.placeholder } ${ node.isRequired ?
                                                                        "*" : ""
                                                                    }`
                                                                }
                                                                required={ node.isRequired }
                                                                value={ values[node.id] || "" }
                                                            />
                                                        </label>
                                                    </div>
                                                ) }
                                                { errors[node.id] && (
                                                    <div className={ classNames.error }>{ errors[node.id] }</div>
                                                ) }
                                            </Fragment>
                                        );
                                    }) }
                                </div>
                                <div className={ classNames.buttonWrapper }>
                                    <Button disabled={ isSubmittingGravityFormsForm } type="submit">
                                        { data.wordpressGravityFormsForm.button.text }
                                    </Button>
                                </div>
                            </form>
                        ) }
                    </section>
                </div>
                <div className={ classNames.contact }>
                    <div className={ classNames.topImages }>
                        <GatsbyImage
                            alt={ data.wordpressPage.topImages.topImageOne.altText }
                            className={ classNames.topImage }
                            image={ data.wordpressPage.topImages.topImageOne.localFile.childImageSharp.gatsbyImageData }
                        />
                        <GatsbyImage
                            alt={ data.wordpressPage.topImages.topImageTwo.altText }
                            className={ classNames.topImage }
                            image={ data.wordpressPage.topImages.topImageTwo.localFile.childImageSharp.gatsbyImageData }
                        />
                    </div>
                    { data.wordpressPage.extraContent && (
                        <Gutenberg content={ data.wordpressPage.extraContent } />
                    ) }
                    <div className={ classNames.container }>

                    </div>
                </div>
                <section className={ classNames.allOurInfo }>
                    <div className={ classNames.container }>
                        <Heading className={ classNames.heading }>{ t("All Our Info") }</Heading>
                        <div className={ classNames.companies }>
                            <section className={ classNames.company }>
                                <Heading className={ classNames.heading }>
                                    { data.wordpress.contactSettings.companies.companyOneName }
                                </Heading>
                                <div className={ classNames.content }>
                                    <div className={ classNames.left }>
                                        <span className={ classNames.street }>
                                            { data.wordpress.contactSettings.companies.companyOneStreet }
                                        </span>
                                        <span className={ classNames.postalCodeAndCity }>
                                            { data.wordpress.contactSettings.companies.companyOnePostalCode }
                                            &nbsp;
                                            { data.wordpress.contactSettings.companies.companyOneCity }
                                        </span>
                                        <span className={ classNames.country }>
                                            { data.wordpress.contactSettings.companies.companyOneCountry }
                                        </span>

                                        <a
                                            className={ classNames.phoneNumber }
                                            href={
                                                `tel:${
                                                    data.wordpress.contactSettings.companies.companyOnePhoneNumber
                                                }`
                                            }
                                        >
                                            <span className={ classNames.label }>
                                                { data.wordpress.contactSettings.companies.companyOnePhoneNumber }
                                            </span>
                                        </a>
                                        <a
                                            className={ classNames.emailAddress }
                                            href={
                                                `mailto:${
                                                    data.wordpress.contactSettings.companies.companyOneEmailAddress
                                                }`
                                            }
                                        >
                                            <span className={ classNames.label }>
                                                { data.wordpress.contactSettings.companies.companyOneEmailAddress }
                                            </span>
                                        </a>
                                    </div>
                                    <div className={ classNames.right }>
                                        <span className={ classNames.vat }>
                                            { t("VAT Nr") }: { data.wordpress.contactSettings.companies.companyOneVat }
                                        </span>
                                        <span className={ classNames.coc }>
                                            { t("CoC Nr") }: { data.wordpress.contactSettings.companies.companyOneCoc }
                                        </span>

                                        <a
                                            className={ classNames.termsAndConditions }
                                            href={
                                                data.wordpress.contactSettings.companies.companyOneTermsAndConditions.link
                                            }
                                            target="_blank"
                                        >
                                            <span className={ classNames.label }>{ t("Terms and Conditions") }</span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                            <section className={ classNames.company }>
                                <Heading className={ classNames.heading }>
                                    { data.wordpress.contactSettings.companies.companyTwoName }
                                </Heading>
                                <div className={ classNames.content }>
                                    <div className={ classNames.left }>
                                        <span className={ classNames.street }>
                                            { data.wordpress.contactSettings.companies.companyTwoStreet }
                                        </span>
                                        <span className={ classNames.postalCodeAndCity }>
                                            { data.wordpress.contactSettings.companies.companyTwoPostalCode }
                                            &nbsp;
                                            { data.wordpress.contactSettings.companies.companyTwoCity }
                                        </span>
                                        <span className={ classNames.country }>
                                            { data.wordpress.contactSettings.companies.companyTwoCountry }
                                        </span>

                                        <a
                                            className={ classNames.phoneNumber }
                                            href={
                                                `tel:${
                                                    data.wordpress.contactSettings.companies.companyTwoPhoneNumber
                                                }`
                                            }
                                        >
                                            <span className={ classNames.label }>
                                                { data.wordpress.contactSettings.companies.companyTwoPhoneNumber }
                                            </span>
                                        </a>
                                        <a
                                            className={ classNames.emailAddress }
                                            href={
                                                `mailto:${
                                                    data.wordpress.contactSettings.companies.companyTwoEmailAddress
                                                }`
                                            }
                                        >
                                            <span className={ classNames.label }>
                                                { data.wordpress.contactSettings.companies.companyTwoEmailAddress }
                                            </span>
                                        </a>
                                    </div>
                                    <div className={ classNames.right }>
                                        <span className={ classNames.vat }>
                                            { t("VAT Nr") }: { data.wordpress.contactSettings.companies.companyTwoVat }
                                        </span>
                                        <span className={ classNames.coc }>
                                            { t("CoC Nr") }: { data.wordpress.contactSettings.companies.companyTwoCoc }
                                        </span>

                                        <a
                                            className={ classNames.termsAndConditions }
                                            href={
                                                data.wordpress.contactSettings.companies.companyTwoTermsAndConditions.link
                                            }
                                            target="_blank"
                                        >
                                            <span className={ classNames.label }>{ t("Terms and Conditions") }</span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
};

export default forwardRef(Contact);
