// extracted by mini-css-extract-plugin
export var contact = "Contact-module--contact--2IYLF";
export var gutenberg = "Contact-module--gutenberg--3t_Cn";
export var container = "Contact-module--container--1PMO1";
export var blocks = "Contact-module--blocks--bfJcR";
export var block = "Contact-module--block--1T9hW";
export var title = "Contact-module--title--28f3-";
export var phoneNumber = "Contact-module--phoneNumber--3scOH";
export var emailAddress = "Contact-module--emailAddress--3gkh4";
export var label = "Contact-module--label--19XNC";
export var button = "Contact-module--button--7xoIt";
export var icon = "Contact-module--icon--Zc4V8";
export var formWrapper = "Contact-module--formWrapper--3W5r2";
export var heading = "Contact-module--heading--mB7ux";
export var description = "Contact-module--description--190MD";
export var confirmation = "Contact-module--confirmation--2fgQ6";
export var form = "Contact-module--form--1SmRL";
export var fields = "Contact-module--fields--25oyV";
export var labelWrapper = "Contact-module--labelWrapper--yOOLW";
export var hidden = "Contact-module--hidden--1ilNX";
export var field = "Contact-module--field--3sAxN";
export var fieldset = "Contact-module--fieldset--1VQ7M";
export var spanOne = "Contact-module--spanOne--n6_Ka";
export var spanTwo = "Contact-module--spanTwo--3tYP5";
export var spanThree = "Contact-module--spanThree--3eBmX";
export var spanFour = "Contact-module--spanFour--_t3S6";
export var spanFive = "Contact-module--spanFive--2VPRm";
export var spanSix = "Contact-module--spanSix--3sCB8";
export var spanSeven = "Contact-module--spanSeven--1enmh";
export var spanEight = "Contact-module--spanEight--38jjl";
export var spanNine = "Contact-module--spanNine--3E9QB";
export var spanTen = "Contact-module--spanTen--hIrst";
export var spanEleven = "Contact-module--spanEleven--2q7Bv";
export var spanTwelve = "Contact-module--spanTwelve--318-B";
export var input = "Contact-module--input--1hhQy";
export var textarea = "Contact-module--textarea--3kvHz";
export var error = "Contact-module--error--JGeZ9";
export var buttonWrapper = "Contact-module--buttonWrapper--1obPC";
export var topImages = "Contact-module--topImages--G63Bx";
export var topImage = "Contact-module--topImage--pkeJh";
export var allOurInfo = "Contact-module--allOurInfo--kbcn5";
export var companies = "Contact-module--companies--2doDy";
export var company = "Contact-module--company--3zIGw";
export var content = "Contact-module--content--3epzB";
export var left = "Contact-module--left--2NsDS";
export var right = "Contact-module--right--3wm99";
export var street = "Contact-module--street--30zFi";
export var postalCodeAndCity = "Contact-module--postalCodeAndCity--kc1m0";
export var country = "Contact-module--country--3jj6Q";
export var vat = "Contact-module--vat--30Yst";
export var coc = "Contact-module--coc--1D6fO";
export var termsAndConditions = "Contact-module--termsAndConditions--2ttd6";